<template>
  <div class="container">
    <SimpleHeader />
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <div class="rank">
        <div class="rank-head">
          <img class="rank-head-bg" src="https://x.jzbull.com/dsx-h5/rank.png"  alt="">
          <div class="rank-body">
            <div class="rank-goods" @click="toSelectGoods">
               <img class="rank-goods-img" src="https://x.jzbull.com/dsx-h5/rank.png" alt="">
               <div class="rank-goods-center">
                 <span>全部商品</span>
                 <span>120件</span>
               </div>
               <div class="rank-goods-right">
                 <span>请选择</span>
                 <van-icon name="arrow" />
               </div>
            </div>
          </div>
        </div>
      </div>
      <van-tabs v-model:active="tabIndex" background="transparent" color="#DABA88">
              <van-tab v-for="(tab,index) in tabs" :title="tab.name"></van-tab>
       </van-tabs>

       <div class="searchBlock">
          <div class="sift-box">
            <div class="sift-box--time">
              <div class="sift-item fcontentCenter" @click="timeChangeShow('startDate')">
                <div :class="{ hasValue: time.startDate != '' }">{{ time.startDate || '开始时间' }}</div>
              </div>
              <div class="sift-item fcontentCenter" @click="timeChangeShow('endDate')">
                <div :class="{ hasValue: time.endDate != '' }">{{ time.endDate || '结束时间' }}</div>
              </div>
            </div>
          </div>
          <div class="filter-btn arrow">筛选</div>
        </div>

        <div class="rank-tb">
          <div class="rank-tb-head">
            <span class="rank-tb-head-it">排名</span>
            <span class="rank-tb-head-it">用户</span>
            <span class="rank-tb-head-it">订单量</span>
          </div>
          <div class="rank-tb-body">
            <div class="rank-tb-cell">
              <div class="rank-tb-cell-it">
                <img class="rank-tb-cell-it-img" src="https://x.jzbull.com/dsx-h5/p1.png" alt="" v-if="true" />
                <span class="rank-tb-cell-it-txt" v-else>1</span>
              </div>
              <div class="rank-tb-cell-it">
                <img class="rank-tb-cell-avatar" src="https://x.jzbull.com/dsx-h5/p1.png" alt="">
                <div class="rank-tb-cell-info">
                  <div class="rank-tb-cell-info-name">llY819159897</div>
                  <div class="rank-tb-cell-info-level">一级代理</div>
                </div>
              </div>
              <div class="rank-tb-cell-it">99.99</div>
            </div>
          </div>
        </div>

    </van-pull-refresh>
    <div class="fixed-bottombox">
      <div class="fixed-bottom">
        <div class="rank-tb">
          <div class="rank-tb-body">
            <div class="rank-tb-cell">
              <div class="rank-tb-cell-it">
                <img class="rank-tb-cell-it-img" src="https://x.jzbull.com/dsx-h5/p1.png" alt="" v-if="true" />
                <span class="rank-tb-cell-it-txt" v-else>1</span>
              </div>
              <div class="rank-tb-cell-it">
                <img class="rank-tb-cell-avatar" src="https://x.jzbull.com/dsx-h5/p1.png" alt="">
                <div class="rank-tb-cell-info">
                  <div class="rank-tb-cell-info-name">llY819159897</div>
                  <div class="rank-tb-cell-info-level">一级代理</div>
                </div>
              </div>
              <div class="rank-tb-cell-it">99.99</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="popBox" v-show="time.timeWhen">
          <van-datetime-picker
            v-model="show_time"
            class="popBottom"
            type="date"
            title="选择时间"
            @confirm="onConfirmTime"
            @cancel="onCancelTime"
          />
    </div>
  </div>
</template>
<script>
import SimpleHeader from '@/components/SimpleHeader'
import { onMounted, nextTick, ref,reactive,toRefs,onUpdated } from 'vue'
import { useRouter } from 'vue-router'
import { Toast, PullRefresh } from 'vant'
import usePage from '@/hooks/usePage'
import useTime from '@/utils/time'

export default {
  name: 'rank',
  components: {
    [PullRefresh.name]: PullRefresh,
    SimpleHeader
  },
  setup() {
    const router = useRouter()
    const state = reactive({
      list:[],
      tabIndex:0,
      tabs: [
        {
            "name": "进货下级",
            "list": [],
            "page": 1,
            "totalnum": "3",
            "hasmore": true,
            "type": "agentall",
            "ajaxing": false,
            "is_show": 0
        },
        {
            "name": "直属团长",
            "list": [],
            "page": 1,
            "totalnum": "4",
            "hasmore": true,
            "type": "inviter_recommend",
            "ajaxing": false,
            "is_show": 0
        }
      ]
    })
    const {
      time,
      show_time,
      onConfirmTime,
      onCancelTime,
      timeChangeShow,
      timePickerShow,
      onConfirm,
      onCancel
    } = useTime()
    const { navigateTo } = usePage()
    // 下拉刷新页面
    const refreshing = ref(false)
    const loading = ref(false)
    const onRefresh = () => {
      refreshing.value = false
      loading.value = true
      getData()
    }
    async function getData() {

    }
    const toSelectGoods = ()=>{
      // router.push('/selectGoods')
      router.push({ path: `/selectGoods` })
    }

    onMounted(async () => {
      getData()
    })
    nextTick(() => {
      window.addEventListener('scroll', () => {})
    })

    return {
      ...toRefs(state),
      refreshing,
      loading,
      onRefresh,
      time,
      show_time,
      timeChangeShow,
      timePickerShow,
      onCancelTime,
      onConfirmTime,
      toSelectGoods
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../common/style/searchpicker';
.container {
  background: #fff;
  min-height: 100%;
  padding-bottom: 60px;
  .rank{
    &-head{
      width: 100%;
      height: 162px;
      position: relative;
      &-bg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 162px;
      }
      .rank-body{
        position: absolute;
        width: calc(100% - 30px);
        height: 70px;
        margin:0 15px;
        bottom: 10px;
        background: rgba(255, 255, 255, .5);
        border-radius: 6px;
        &::before{
          content: "";
          position: absolute;
          left: 20%;
          border-width: 8px;
          border-color: rgba(255, 255, 255, .5);
          border-top-width: 0;
          border-style: dashed;
          border-top-style: solid;
          border-left-color: transparent;
          border-right-color: transparent;
          transform: translateY(-100%);
        }
      }
    }
    &-goods{
      // margin-top: 20px;
      padding: 10px 15px;
      background: transparent;
      display: flex;
      flex-direction: row;
      align-items: center;
      align-content: stretch;
      &-img{
        display: inline-block;
        width: 50px;
        height: 50px;
        // flex-shrink: 0;
      }
      &-center{
        padding: 5px 0;
        height: 50px;
        flex: 1;
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      &-right{
        // flex-shrink: 0;
      }
    }
  }
}
.rank-tb{
  padding: 0 15px;
  &-head{
    background: #f8f8f8;
    height: 36px;
    line-height: 36px;
    display: flex;
    align-items: center;
    &-it{
      padding: 0 10px;
      &:nth-of-type(1){
        width: 50px;
        flex-shrink: 0;
      }
      &:nth-of-type(2){
        flex: 1;
      }
      &:nth-of-type(3){
        width: 80px;
        flex-shrink: 0;
        text-align: right;
      }

    }
  }
  &-body{

  }
  &-cell{
    display: flex;
    align-items: center;
    &-it{
      height: 60px;
      padding:5px 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      box-sizing: border-box;
      &:nth-of-type(1){
        width: 50px;
        flex-shrink: 0;
      }
      &:nth-of-type(2){
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
      }
      &:nth-of-type(3){
        width: 80px;
        flex-shrink: 0;
        text-align: right;
      }
      &-img{
        width: 28px;
        height: 28px;
      }

    }
    &-avatar{
      width: 45px;
      height: 45px;
      border-radius: 45px;
    }
    &-info{
      padding: 5px 10px;
      display: flex;
      flex-direction: column;
      &-name{
        font-weight: bold;
      }
      &-level{
        margin-top: auto;
      }
    }
  }
}
.rank-tb-cell+.rank-tb-cell{
  border-top: 1px solid #f8f8f8;
}
.fixed-bottombox{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  .fixed-bottom{
    clear: both;
    margin: 0 auto;
    width: 375px;
    background:  #FEF1E5;
  }
  .rank-tb{
    width: 100%;
  }
}
.searchBlock {
  padding: 10px 15px 0;
  display: flex;
  align-items: flex-start;
}
.sift-box {
  margin-bottom: 8px;
}
.filter-btn{
  margin-left: auto;
  height: 36px;
  line-height: 36px;
  padding-right: 30px;
}
.arrow {
  position: relative;
      &:after {
        position: absolute;
        content: '';
        top: 50%;
        transform: translateY(-50%);
        right: 12px;
        border-top: 5px solid #1a1a1a;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
      }
    }
    .sift-box{
      width: 70%;
    }
    .sift-item{
      flex: 1;
    }
</style>